<template>
  <MDBCol :col="props.col" class=" d-flex flex-row flex-wrap p-0">
    <div
      class="inside-block flex-grow-1"
    >
      <div class="bg-box d-flex flex-column p-3">
        <div class="fst-italic text-muted">
          {{ $tcust('CheckingView.roomsCountTitle') }}
        </div>
        <div class="my-2 ps-3 text-start">
          <span :class="rooms > 0 ? '' : 'fst-italic text-muted'">
            {{ $tcust('checking.roomsCount', { n: rooms }) }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="inside-block flex-grow-1"
    >
      <div class="bg-box d-flex flex-column p-3">
        <div class="fst-italic text-muted">
          {{ $tcust('checking.annexes.title') }}
        </div>
        <div
          v-if="props.checking.Annexes.IsEmpty"
          class="fst-italic text-muted pt-2 ps-3"
        >
          {{ $tcust('checking.annexes.empty') }}
        </div>
        <div
          v-else
          class="ps-3"
        >
          {{ props.checking.Annexes.length }} annexes
        </div>
      </div>
    </div>
  </MDBCol>
</template>

<script setup>
import {
  MDBCol
} from "mdb-vue-ui-kit";

import { computed, defineProps } from 'vue'

const props = defineProps({
  checking: {
    type: Object,
    default: null,
  },
  col: {
    type: Number,
    default: null,
  },
});

const rooms = computed(() => {
  try {
    return props.checking.Rooms.length
  } catch (error) {
    return 0
  }
});
</script>
