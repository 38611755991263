<template>
  <Summary
    :id="'summary-' + props.checking.Id"
    :appearance="appearance"
  >
    <template #Card>
      <component
        :is="StyleCard"
        :collapse-button="props.collapseButton"
      />
    </template>
    <template #List>
      <component
        :is="StyleList"
        :collapse-button="props.collapseButton"
      />
    </template>
  </Summary>
</template>

<script setup>
import StyleCard from "./StyleCard.vue";
import StyleList from "./StyleList.vue";

import { defineProps } from "vue";

const props = defineProps({
  checking: {
    type: Object,
    default: null,
  },
  appearance: {
    type: String,
    default: null,
  },
  collapseButton: {
    type: Boolean,
    default: true,
  },
});

import CheckingAppointmentSetup from "./CheckingAppointmentSetup";
CheckingAppointmentSetup(props.checking);
</script>
