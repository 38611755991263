<template>
  <div class="p-3">
    <div
      class="position-relative d-flex flex-column justify-content-center align-items-center p-0 m-auto mt-n3 w-fit h-fit"
    >
        <Accommodation
          :accommodation="accommodation"
          :appearance="'List'"
          :collapse-button="false"
        />
        <button
          role="link"
          class="position-absolute top-0 end-0 btn btn-primary rounded-3 d-flex flex-row align-items-center mt-n2 me-n1 py-1 px-3 w-fit fw-bold"
          @click="$router.push({ name: 'AccommodationUpdate' })"
        >
          <span>
            {{ $tcust('common.form.modify') }}
          </span>
        </button>
    </div>

    <hr class="text-primary">

    <!-- CheckingsList -->
    <DashBoard v-if="accommodationSetup.loaded && accommodationSetup.checkingsList">
      <div
        v-for="(checking, index) of accommodationSetup.checkingsList"
        :key="index"
        class="m-3"
      >
        <CheckingAppointment
          :checking="checking"
          :appearance="accommodationSetup.checkingsList.length > 1 ? null : 'List'"
          :collapseButton="false"
          class="custom-size"
        />
      </div>
    </DashBoard>

    <Loader v-else />
  </div>
</template>

<script setup>
import Accommodation from "@/components/summary/accommodation/Accommodation.vue";
import CheckingAppointment from "@/components/summary/checkingAppointment/CheckingAppointment.vue";

import { useStore } from "vuex";

const store = useStore();

const accommodation = store.state.accommodationChosen;

import AccommodationSetup from "@/components/summary/accommodation/AccommodationSetup";
const { accommodationSetup } = AccommodationSetup(accommodation);

import Setup from "./AccommodationViewSetup";
Setup(accommodation);
</script>

<style lang="scss" scoped>
.AccommodationViewCollapse {
  transition: opacity 0.2s;
}
.custom-size {
  max-width: 500px;
}

@media (max-width: 359.98px) {
  .p-3 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
</style>
