<template>
  <CardContainer
    v-slot="slotProps"
    :impose-appearance="'List'"
    :collapse-button="props.collapseButton"
    class="custom-size gradient m-auto"
  >
    <TopRightButton
      v-if="$route.name !== 'Checking'"
      @click="checkingAppointmentSetup.goToCheckPage()"
    />

    <CheckingAppointment
      :col="12"
      :checking="checkingAppointmentSetup.checking"
    />

    <RoomsToCheck
      :col="12"
      :checking="checkingAppointmentSetup.checking"
    />

    <MDBCollapse
      v-if="false"
      :id="slotProps.collapseId"
      v-model="slotProps.cardCollapse"
      class="p-0 row m-auto"
    >
      <MDBRow
        class="d-flex flex-row flex-grow-1 mw-100 m-0 p-0"
      >
        <Owners
          :col="6"
          :checking="checkingAppointmentSetup.checking"
        />

        <Occupants
          :col="6"
          :checking="checkingAppointmentSetup.checking"
        />

        <Experts
          :col="6"
          :checking="checkingAppointmentSetup.checking"
        />

        <Representatives
          :col="6"
          :checking="checkingAppointmentSetup.checking"
        />
      </MDBRow>
    </MDBCollapse>
  </CardContainer>
</template>

<script setup>
import {
  MDBCollapse,
  MDBRow
} from "mdb-vue-ui-kit";

import CheckingAppointment from '@/components/summary/common/CheckingAppointment';
import Experts from '@/components/summary/common/Experts';
import Occupants from '@/components/summary/common/Occupants';
import Owners from '@/components/summary/common/Owners';
import Representatives from '@/components/summary/common/Representatives';
import RoomsToCheck from '@/components/summary/common/RoomsToCheck';
import TopRightButton from '@/components/summary/common/TopRightButton';

import { defineProps, inject } from 'vue'

const props = defineProps({
  collapseButton: {
    type: Boolean,
    default: true,
  },
});

const checkingAppointmentSetup = inject('checkingAppointmentSetup')
</script>
