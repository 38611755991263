import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default function Setup(accommodation) {
  const router = useRouter();

  onMounted(() => {
    if (!accommodation) router.go(-1);
  });

  return
}
