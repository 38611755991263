<template>
  <CardContainer
    v-slot="slotProps"
    :impose-appearance="'Card'"
    :collapse-button="props.collapseButton"
    class="custom-size gradient m-auto"
  >
    <TopRightButton
      v-if="$route.name !== 'Checking'"
      @click="checkingAppointmentSetup.goToCheckPage()"
    />

    <CheckingAppointment
      :col="12"
      :checking="checkingAppointmentSetup.checking"
      :contraction="true"
    />

    <RoomsToCheck
      :col="12"
      :checking="checkingAppointmentSetup.checking"
    />

    <MDBCollapse
      v-if="false"
      :id="slotProps.collapseId"
      v-model="slotProps.cardCollapse"
      class="p-0"
    >
      <Owners
        :col="12"
        :checking="checkingAppointmentSetup.checking"
      />

      <Occupants
        :col="12"
        :checking="checkingAppointmentSetup.checking"
      />

      <Experts
        :col="12"
        :checking="checkingAppointmentSetup.checking"
      />

      <Representatives
        :col="12"
        :checking="checkingAppointmentSetup.checking"
      />     
    </MDBCollapse>

    <!-- Details -->
    <MDBCol
      col="12"
      class="p-0"
    >
      <MDBRow
        class="d-flex flex-row w-100 h-100 m-0"
      />
    </MDBCol>
  </CardContainer>
</template>

<script setup>
import {
  MDBCol,
  MDBCollapse,
  MDBRow,
} from "mdb-vue-ui-kit";

import CheckingAppointment from '@/components/summary/common/CheckingAppointment';
import Experts from '@/components/summary/common/Experts';
import Occupants from '@/components/summary/common/Occupants';
import Owners from '@/components/summary/common/Owners';
import Representatives from '@/components/summary/common/Representatives';
import RoomsToCheck from '@/components/summary/common/RoomsToCheck';
import TopRightButton from '@/components/summary/common/TopRightButton';

import { defineProps, inject } from 'vue'

const props = defineProps({
  collapseButton: {
    type: Boolean,
    default: true,
  },
});

const checkingAppointmentSetup = inject('checkingAppointmentSetup')
</script>
